body {
    margin: 0px;
    padding: 0px;

}


.mainhomepage {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-image: linear-gradient(rgb(34 34 34 / 50%), rgba(0, 0, 0, 0.5)), url("../assets/images/Hero.png");
    background-position: 69%;
    /* background-image: url("../../public/assets/images/Hero.png"); */
    display: flex;
    /* padding-left: 20vh; */
    /* align-items: center; */
    flex-direction: column;
}

.test {
    background-color: black;
    padding-left: 5%;
    padding-right: 5%;

}

.mainhomepageh1 {

    font-weight: 600;
    font-size: 5rem;
    color: white;
    font-family: "League Spartan", sans-serif;

margin-bottom: 10px;
}

.mainhomepageh2 {
    color: rgba(252, 183, 21, 1);
    font-size: 4rem;
    margin-top: 0;
    margin-bottom: 0px;

    font-family: "League Spartan", sans-serif;
}
.mainheading{
    padding-left: 20vh;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainheading{
    padding-left: 8vh;

}
} */

.mainheading p{
    font-weight: 600;
    font-size: 20px;
    color: white;
    /* width: 45vw; */
}

.maincount{
    background-color: rgba(38, 38, 38, 1);
    min-height: 17vh;
}

.maincount{
    display: flex;
   /* padding-left: 20%; */

    align-items: center;
    justify-content: center;

   
}
.maincount1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* For larger screens */
    text-align: center;
    gap: 100px;
    margin-bottom: 20px;
}


.divs h1{
    
    color: rgba(252, 183, 21, 1);
    font-weight: 600;
    font-size: 3rem;
    line-height: 47.84px;
    margin-bottom: 0px;
    font-family: "League Spartan", sans-serif;

}
.divs h3{
    color: white;
    font-family: "League Spartan", sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 20.24px;
}


.learning-solutions {
    text-align: center;
    color: #fff;
    padding: 50px 20px;
    background-color: #1c1c1c;
    font-family: "League Spartan", sans-serif;
  }
  
  .learning-solutions h2 {
    font-family: "League Spartan", sans-serif;
    font-size: 36px;
    color: rgba(252, 183, 21, 1);
    
  }
  
  .learning-solutions p {
    font-size: 16px;
    font-family: "League Spartan", sans-serif;
    color: #fff;
    margin-bottom: 40px;
  }
  
  .solutions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .solution-card {
    background-color: #333;
   padding-top: 20px;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 280px;
  }
  
  .solution-card img {
    width: 50px;
    margin-bottom: 20px;
  }
  
  .solution-card h3 {
    color: rgba(252, 183, 21, 1);
    font-size: 22px;
    margin-bottom: 10px;
    font-family: "League Spartan", sans-serif;
  }
  
  .solution-card p {
    color: #ccc;
    font-size: 14px;
    font-family: "League Spartan", sans-serif;
  }

  .different{
    background-color: rgba(252, 183, 21, 1);
    color: black;
  }
  .different h3{
    color: black;
    font-family: "League Spartan", sans-serif;
  }
  
  .different p{
    color: black;
    font-family: "League Spartan", sans-serif;
    padding-left: 5px;
    padding-right: 5px;
  }
  

  .hero-section {
 
    position: relative;
    background-size: cover;
    background-position: center;
    color: #fff;
    height: 80vh;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 0 20px;
  }
  
  .hero-content {
    
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-image: linear-gradient(rgb(34 34 34 / 50%), rgba(0, 0, 0, 0.5)), url("../assets/images/2nd\ image.jpg");
    background-position: 69%;
    max-width: 120vw;
    background-color: rgba(0, 0, 0, 0.6); 
    padding: 40px;

    border-radius: 10px;
    position: relative;
  }
  
.pioneer{
    width: 50vw;
}
  
  .hero-content h1 {
    font-size: 36px;
    color: rgba(252, 183, 21, 1);
    text-transform: uppercase;
    margin-top: 50px; 
    font-family: "League Spartan", sans-serif;
  }
  
  .hero-content p {
    font-size: 20px;
    margin: 20px 0;
    line-height: 1.6;
    font-family: "League Spartan", sans-serif;
  }
  
  .hero-button {
    background-color: rgba(252, 183, 21, 1);
    color: #1c1c1c;
    padding: 10px 20px;
    border: none;
    border-radius: 33.5px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "League Spartan", sans-serif;
    font-weight: 600;

  }
  
  .hero-button:hover {
    background-color: rgba(255, 127, 62, 1);
  }
  .hero-section1{
    
    display: flex;
    justify-content: center;
    align-items: center;
 
  }
  
  .hero-container1 {
   
    max-width: 1100px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    background-color: #ffa800; 
    padding: 40px;
    display: flex;
  
    min-height: 50vh;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  
  .hero-profile1 {
    position: relative;
    width: 50%;
  }
  
  .hero-profile1 img {
    width: 100%;
    border-radius: 10px;
  }
  
  .hero-text1 {
    width: 50%;
    color: #000;
    text-align: left;
  }
  
  .hero-text1 h1 {
    font-size: 48px;
    font-weight: bold;
  }
  
  .hero-text1 h2 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .hero-text1 p {
    font-size: 16px;
    margin: 20px 0;
  }
  
  .hero-subscribe1 {
    margin-top: 20px;
  }
  
  .subscribe-box1 {
    display: flex;
    align-items: center;
  }
  
  .subscribe-box1 input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 300px;
    margin-right: 10px;
  }
  
  .subscribe-btn {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .subscribe-btn:hover {
    background-color: #333;
  }

 
  

@media (max-width: 768px) {
  .hero-container1 {
    flex-direction: column;
  }

  .hero-profile1, .hero-text1 {
    width: 100%;
 
  }

  .hero-text1 h1 {
    font-size: 36px;
  }

  .subscribe-box1 input {
    width: 200px;
  }
    .mainhomepage {
        min-height: 100vh;
    }
    .mainheading{
        padding: 1.5vh;
        height: 53vh;
    }

    .mainhomepageh1 {

        font-weight: 600;
        font-size: 2.8rem;
        /* width: 380px; */
    
    margin-bottom: 10px;
    }
    
    .mainhomepageh2 {
        color: rgba(252, 183, 21, 1);
        font-size: 3rem;
      
    }
    .solutions-grid {
        grid-template-columns: 1fr;
      }

      .hero-section {
        height: auto;
        padding: 50px 20px;
      }
    
      .hero-content h1 {
        font-size: 28px;
      }
    
      .hero-content p {
        font-size: 14px;
        font-size: 15px;
        font-weight: 300;
      }
    
      .profile-image {
        width: 60px;
        height: 60px;
        top: -30px;
      }
      .pioneer{
        width: 80vw;
    }
    .hero-content {
   padding-left: 30px;
    }

    .hero-container1{
      width: 75%;
    }

    .subscribe-box1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
  }
}

/* For tablet screens */
@media (max-width: 1024px) {
    .maincount1 {
        grid-template-columns: repeat(2, 1fr); 
        gap: 50px;
    }
}


@media (max-width: 600px) {
  /* .mainheading{
    padding: 3vh;
  } */
  .mainhomepageh1{
    font-size: 2.3rem;
  }
  .mainhomepageh2{
    font-size: 2rem;
  
  }
    .maincount1 {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    /* .mainhomepageh1{
      font-size: 2.3rem;
    }
    .mainhomepageh2{
      font-size: 2rem;
    
    }
    .hero-container1{
    
        width: 68%;
    
    } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mainheading {
    padding-left: 10vh;
    
}
.hero-section {

  height: 50vh;
}

.hero-container1{
  min-height: 30vh;
}
.navbar-logo {
  width: 290px;
  max-width: 290px;
}
.maincount {
margin-bottom: 80px;
}
.benefits-grid {

 
  margin-bottom: 80px;
}
.learning-solutions {

  margin-bottom: 80px;
}
}