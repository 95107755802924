
body {
    margin: 0;

    background-color: #141414;
    color: #fff;
}

.container h1, h3 {
    text-align: center;
    margin: 0;
    padding: 10px 0;
    font-family: "League Spartan", sans-serif;
}

 .container h1 {
    font-size: 36px;
}

.container h3 {
    font-size: 24px;
    color: #ccc;
}


.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}


.benefits-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.benefit-item {
    background-color: #252525;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    gap: 20px;
}
.partimg{
    width: 4rem;
    height: 4rem;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(252, 183, 21, 1) 0%, rgba(255, 127, 62, 1) 100%);
}
.partimg img{
    height: 30px;
    width: 30px;
 
}


.benefit-item h4 {
    font-size: 20px;
    margin: 10px 0;
    font-family: "League Spartan", sans-serif;
}

.benefit-item p {
    font-size: 16px;
    color: #ccc;
    font-family: "League Spartan", sans-serif;
}


@media (max-width: 768px) {
    .benefits-grid {
        grid-template-columns: 1fr; 
}
.partimg{
    width: 6rem;
    height: 4rem;
}
}

@media (max-width: 480px) {
    .container h1 {
        font-size: 28px;
    }

    .container h3 {
        font-size: 18px;
    }

    .benefit-item h4 {
        font-size: 18px;
    }

    .benefit-item p {
        font-size: 14px;
        
    }
}
