
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7); 
    padding: 0.5rem 2rem; 
    border-radius: 20px;
    width: 90vw; 
    min-height: 40px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto; 
    margin-top: 30px;
  }
  

  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    width: 180px;
    max-width: 180px; 
    min-height: 62px;
    height: 62px;
    height: auto;
  }

  .navbar-center {
    display: flex;
    justify-content: center;
  }
  
  .navbar-profile {
    width: 8vw;
    max-width: 50px; 
    height: auto; 
    border-radius: 50%;
    border: 2px solid #fff;
  }
  

  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .contact-btn {
    background-color: #fbbf24; 
    color: #000;
    border: none;
    padding: 0.7rem 1.5rem; 
    border-radius: 20px;
    font-size: 1rem; 
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .contact-btn:hover {
    background-color: #d9a20a; 
  }
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: row; 
      width: 90vw;
      margin-top: 20px;
      padding: 1rem;
      display: flex;
      justify-content: center;
    }
  
    /* .navbar-logo,
    .navbar-profile {
      width: 12vw;
      max-width: 40px; 
    } */
  
    .contact-btn {
      margin-top: 10px; 
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    /* .navbar-logo,
    .navbar-profile {
      width: 15vw;
      max-width: 30px; 
    } */
  
    .contact-btn {
      font-size: 0.9rem; 
      padding: 0.5rem 1rem; 
    }
  }
  