.footer {
    background-color: #1b1b1b;
    color: #fff;
    padding: 40px 0;
    font-family: 'Arial', sans-serif;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  .footer-logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-logo img {
    width: 174px;
    height: 94px;
  }
  
  .footer-images {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  /* .footer-person {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
  } */
  
  .footer-section {
    flex: 1;
    margin: 20px 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: revert-layer; */
    align-items: center;
  }
  
  .footer-section h3 {
    font-size: 18px;
    /* margin-bottom: 10px; */
    font-family: "League Spartan", sans-serif;
    margin-right: 30px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;

  }
  
  .footer-section ul li {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 18px;
    font-family: "League Spartan", sans-serif;
  }
  
  .footer-section ul li i {
    margin-right: 10px;
  }
  .footer-bottom{
    display: flex;
    justify-content: center;
  }
  .innertext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid #333;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    margin: 0;
    font-family: "League Spartan", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }
  

  



  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-bottom {
      flex-direction: column;
    }
  
    .footer-bottom p {
      margin-bottom: 10px;
    }
  }

.footer-bottom {
  width: 100%;

  padding: 20px 0;
  text-align: center;
}

.innertext {
  padding-left: 20px;
  padding-right: 20px;
  width: 90%; 
  max-width: 1200px; 
  background-color: rgba(217, 217, 217, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 13px;
  margin: auto;
  text-align: center;
  display: flex;
flex-wrap: wrap;
}

.footer-social-icons {
  display: flex;
  justify-content: center; /* Center icons */
  gap: 15px;
  flex-wrap: wrap;

}

.footer-social-icons .icon {
  background-color: #ffffff;
  color: black; 
  font-size: 5vw;
  padding: 2vw; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 10vw; 
  transition: background-color 0.3s ease;
}

.footer-social-icons .icon:hover {
  background-color: rgba(252, 183, 21, 1);
}
.sociallogo{
  color: black;
    font-size: inherit;
    width: 25px;
    height: 25px;
}

@media (min-width: 768px) {
  .footer-social-icons .icon {
    font-size: 32px; 
    padding: 12px;
    width: 20px;
    height: 20px;
  }

  .innertext{
    width: 85%;
  }
  .innertext{
    width: 85%;
   
  }
}


.footer-bottom p {
  font-size: 4vw; 
}

@media (min-width: 768px) {
  .footer-bottom p {
    font-size: 16px; 
  }
  
}

@media (max-width: 600px) {
  .innertext {
   
    width: 85%;
    max-width: 1200px;
   
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.innertext p {
  order: 2; 
}

.innertext .footer-social-icons {
  order: 1;
}

.add{
  line-height: 1.3;
}
}

